function parseQuery(queryString) {
  var query = {};
  var pairs = (queryString[0] === "?"
    ? queryString.substr(1)
    : queryString
  ).split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
}

function get(object, key, default_value) {
  var result = object[key];
  return typeof result !== "undefined" ? result : default_value;
}

const query = parseQuery(window.location.search);

const utm_source = get(query, "utm_source", "");
const utm_medium = get(query, "utm_medium", "");
const utm_campaign = get(query, "utm_campaign", "");
const utm_content = get(query, "utm_content", "");

if (utm_source || !localStorage.getItem("utm_source")) {
  localStorage.setItem("utm_source", utm_source);
  localStorage.setItem("utm_medium", utm_medium);
  localStorage.setItem("utm_content", utm_content);
  localStorage.setItem("utm_campaign", utm_campaign);
}
