import Masonry from "masonry-layout";

let msnry;
const masonryGrid = document.querySelectorAll(".masonry-grid");

masonryGrid.forEach(elem => {
  msnry = new Masonry(elem, {
    columnWidth: ".grid-sizer",
    itemSelector: ".publication-promo",
    percentPosition: true,
    gutter: 5
  });
});
