document.addEventListener("DOMContentLoaded", () => {
  let slideIndex = 0;
  const slides = document.getElementsByClassName("sticker");

  if (slides !== null && slides.length > 0) {
    showSlides();
  }

  function showSlides() {
    let i;

    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }

    slideIndex++;

    if (slideIndex > slides.length) {
      slideIndex = 1;
    }

    slides[slideIndex - 1].style.display = "block";
    setTimeout(showSlides, 2500);
  }
});
