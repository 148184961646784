import $, { each } from "jquery";

const ready = function() {
  let currentPoint = [];
  let targetPoint = [];

  if ($(".scroll-parallax").length) {
    $(".scroll-parallax").each(function(index) {
      let containerTop = $(this).offset().top - $(window).height();
      currentPoint[index] =
        $(window).scrollTop() > containerTop
          ? $(window).scrollTop() - containerTop
          : 0;
    });

    $(window).scroll(scrollPoint);
    scrollPoint();

    function scrollPoint() {
      $(".scroll-parallax").each(function(index) {
        let containerTop = $(this).offset().top - $(window).height();
        targetPoint[index] =
          $(window).scrollTop() > containerTop
            ? $(window).scrollTop() - containerTop
            : 0;
      });
    }

    function softParallax() {
      $(".scroll-parallax").each(function(index) {
        currentPoint[index] += (targetPoint[index] - currentPoint[index]) / 5;

        $(".scroll-parallax .moving").css(
          "transform",
          "translateY(" + (currentPoint[index] / 200 - 1) + "em)"
        );
      });
    }

    const cycler = setInterval(softParallax, 10);
  }
};

$(document).ready(ready);
