const handlerClick = event => {
  const target = event.target.closest("a");

  if (target) {
    const href = target.getAttribute("href");

    if (href.startsWith("https://my.anabar.id")) {
      event.preventDefault();
      const utmParams = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_content",
        "referrer"
      ];
      const utm = {};

      utmParams.forEach(paramName => {
        const param = localStorage.getItem(paramName);

        if (param) {
          utm[paramName] = param.toString();
        }
      });

      window.location.href = `${href}${
        Object.keys(utm).length ? `?${new URLSearchParams(utm).toString()}` : ""
      }`;
    }
  }
};

document.body.addEventListener("click", handlerClick);
