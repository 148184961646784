const form = document.querySelector("#form");
const emailInput = document.querySelector("#email");
const nameInput = document.querySelector("#name");
const submitButton = document.querySelector("#submit-btn");

if (form && submitButton) {
  const checkInput = input => input.value.trim().length > 0;

  const inputHandler = inputElem => {
    return evt => {
      if (checkInput(evt.target)) {
        inputElem.classList.remove("is-invalid");

        if (
          (inputElem === emailInput && checkInput(nameInput)) ||
          (inputElem === nameInput && checkInput(emailInput))
        ) {
          submitButton.removeAttribute("disabled");
        }
      } else {
        inputElem.classList.add("is-invalid");
        submitButton.setAttribute("disabled", true);
      }
    };
  };

  emailInput.addEventListener("input", inputHandler(emailInput));
  emailInput.addEventListener("blur", inputHandler(emailInput));

  nameInput.addEventListener("input", inputHandler(nameInput));
  nameInput.addEventListener("blur", inputHandler(nameInput));

  form.addEventListener("submit", async evt => {
    evt.preventDefault();

    const utmSource = localStorage.getItem("utm_source");
    const utmMedium = localStorage.getItem("utm_medium");
    const utmCampaign = localStorage.getItem("utm_campaign");
    const utmContent = localStorage.getItem("utm_content");

    const utm = {
      utm_source: utmSource && utmSource.toString(),
      utm_medium: utmMedium && utmMedium.toString(),
      utm_campaign: utmCampaign && utmCampaign.toString(),
      utm_content: utmContent && utmContent.toString()
    };

    const response = await fetch(
      "https://anabar.ai/registration-request/v1/save-request",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          name: nameInput.value.trim(),
          email: emailInput.value.trim(),
          utm: utm
        })
      }
    );

    if (response.ok) {
      document.location.href = document.location.origin + "/thank-you";
    }
  });
}
