import $ from "jquery";

import "../styles/main.scss";
import "./nav-mobile";
import "./awakeness";
import "./scroll-links";
// import "./prices";
import "./table-infogr";
import "./scroll-parallax";
import "./utm-saver";
import "./plyr";
import "./screenshot-gallery";
import "./slideshow";
import "./signup";
import "./interceptingLink";
import "./referrer-saver";
import "./publications";

const ready = function() {};

$(document).ready(ready);

$(".pseudo-browser a").on("click", event => {
  event.stopPropagation();
});
