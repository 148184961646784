import $ from "jquery";

const ready = function() {
  $(".js-scroll-link").on("click", function(e) {
    e.preventDefault();

    var linkOffset = -90;

    if ($($.attr(this, "href")).data("scroll-link-offset")) {
      const headerHeight = document.querySelector("header").clientHeight;
      linkOffset = -1 * headerHeight * 3.95;
    }

    $(".mobile-nav-sidebar").removeClass("visible");

    $("html, body").animate(
      {
        scrollTop: $($.attr(this, "href")).offset().top + linkOffset
      },
      500
    );
  });
};

$(document).ready(ready);
