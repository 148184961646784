import Plyr from "plyr";

document.addEventListener("scroll", function() {
  Array.from(document.querySelectorAll(".plyr-player")).map(
    p =>
      new Plyr(p, {
        controls: ["play", "progress", "current-time", "volume", "fullscreen"]
      })
  );
});
